<template>
  <div class="container">
    <div class="img">
      <img src="@/assets/images/404.gif" title="not found 404" alt="not found 404"/>
    </div>
    <div class="txt">
      <p class="tip-msg">Page not found</p>
      <h1 class="tip-msg">———  找不到页面了 ———</h1>
    </div>
    <div class="btn">
      <a href="/" id="timer">返回首页</a>
      <a href="javascript:history.go(-1)">返回上一页</a>
      <div class="clearfix"></div>
      <p><a href="https://www.thisadmin.cn" class="link">如有软件需求或疑问，点我联系研发团队。</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: '404',
  components: {}
}
</script>
<style lang="scss" scoped>
.container{overflow: hidden; position: relative;background-color: #ffffff !important;height: 100vh;
  .img{width:1100px; margin: 0 auto; text-align: center;padding-top:10px;
      img{display: block;width:100%;}
    }
  .txt{position: relative;top:-100px; text-align: center;
    h1{font-size: 30px; height: 60px; line-height: 60px;}
    p{font-size: 20px;}
    }
.btn{position: relative;top:-100px; width: 800px; margin: 0 auto; text-align: center; margin-top: 20px;
a{display: inline-block; width: 160px; height: 48px; border-radius: 5px; border:  1px solid #1e80c2; line-height: 48px; text-align: center; color: #1e80c2; font-size: 20px;transition: background-color 0.8s;}
a:hover{background-color: #1e80c2; color: #fff;}
p{text-align: center;font-size: 16px;margin-top: 30px; height: 60px; line-height: 60px;
.link{color: #333;text-align: center;border: none;width: 100%;font-weight: bold;}
.link:hover{color: #1e80c2;background-color:transparent;}
}
.logo{display: block;width: 100px; height: 60px; line-height: 60px; font-size: 14px; position: relative;margin-top: 30px;}
}
@media (max-width: 800px){
  .btn{ width: 100%;}
}

}
</style>
